<template>
  <form>
    <b-form-row :style="corBackground()">
			<!-- #region dados basicos -->
			<b-col md="3">
        <b-form-group :class="corTextoInput()" label="CPF/CNPJ" label-align-md="right" label-size="" label-cols-md="3" content-cols-md="">
					<b-input-group v-if="buscarCliente">
						<b-form-input  type="text" v-model="obj.cpfCnpj" @keyup="mascaraCpfCnpj()" @blur="buscaClienteBlur()" autocomplete="off" placeholder="Opcional" :disabled="criterioBloqueioEdicao()" />
            <template #append>
              <b-button variant="primary" type="button" @click="buscaCliente()" :disabled="criterioBloqueioEdicao()">
                <b-icon scale="1" icon="search" v-b-tooltip.hover title="Pesquisar" />
              </b-button>
            </template>
          </b-input-group>
					<b-input-group v-else-if="buscarRemetente || buscarDestinatario">
						<b-form-input  type="text" v-model="obj.cpfCnpj" @keyup="mascaraCpfCnpj()" autocomplete="off" placeholder="Opcional" :disabled="criterioBloqueioEdicao()"/>
            <template #append>
              <b-button variant="primary" type="button" @click="buscaPessoa()" :disabled="criterioBloqueioEdicao()">
                <b-icon scale="1" icon="search" v-b-tooltip.hover title="Pesquisar" />
              </b-button>
            </template>
          </b-input-group>
		  <b-form-input v-else type="text" v-model="obj.cpfCnpj" @keyup="mascaraCpfCnpj()" autocomplete="off" placeholder="Opcional" :disabled="criterioBloqueioEdicao()"/>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group :class="corTextoInput()" label="Nome" label-align-md="right" label-size="" label-cols-md="2" content-cols-md="">
					<b-input-group v-if="buscarCliente">
						<b-form-input type="text" v-model="obj.nome" autocomplete="off" :disabled="criterioBloqueioEdicao()"/>
            <template #append>
              <b-button variant="primary" type="button" @click="buscaCliente(true)" :disabled="criterioBloqueioEdicao()">
                <b-icon scale="1" icon="search" v-b-tooltip.hover title="Pesquisar" />
              </b-button>
            </template>
          </b-input-group>
					<b-input-group v-else-if="buscarRemetente || buscarDestinatario">
						<b-form-input type="text" v-model="obj.nome" autocomplete="off" :disabled="criterioBloqueioEdicao()"/>
            <template #append>
              <b-button variant="primary" type="button" @click="buscaPessoa(true)" :disabled="criterioBloqueioEdicao()">
                <b-icon scale="1" icon="search" v-b-tooltip.hover title="Pesquisar" />
              </b-button>
            </template>
          </b-input-group>
					<b-form-input v-else type="text" v-model="obj.nome" autocomplete="off" :disabled="criterioBloqueioEdicao()" />
        </b-form-group>
      </b-col>

			<b-col md="3" v-if="exibeCubagem">
        <b-form-group :class="corTextoInput()" label="Aos cuidados" label-align-md="right" label-size="" label-cols-md="5">
          <b-form-input type="text" v-model="obj.aosCuidados" autocomplete="off" placeholder="Opcional" :disabled="criterioBloqueioEdicao(config.isPrePostagem)"/>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group :class="corTextoInput()" label="Email" label-align-md="right" label-size="" label-cols-md="2">
          <b-form-input type="text" v-model="obj.email" autocomplete="off" placeholder="Opcional" :disabled="criterioBloqueioEdicao()"/>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group :class="corTextoInput()" label="Telefone" label-align-md="right" label-size="" label-cols-md="4" content-cols-md="">
          <b-form-input type="text" v-model="obj.telefone" @keyup="mascaraTelefone()" autocomplete="off" placeholder="Opcional" :disabled="criterioBloqueioEdicao()"/>
        </b-form-group>
      </b-col>
    <!-- #endregion -->

    	<!-- #region endereco -->
      <b-col md="2">
        <b-form-group :class="corTextoInput()" label="Cep" label-align-md="right" label-size="" label-cols-md="2" content-cols-md="">
          <b-form-input type="text" v-model="obj.endereco.cep" @keyup="mascaraCep($event)" maxlength="9" autocomplete="off" @blur="buscaCep()" :disabled="criterioBloqueioEdicao()"/>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group :class="corTextoInput()" label="Endereço" label-align-md="right" label-size="" label-cols-md="3">
          <b-form-input type="text" v-model="obj.endereco.logradouro" autocomplete="off" :disabled="criterioBloqueioEdicao()"/>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group :class="corTextoInput()" label="Bairro" label-align-md="right" label-size="" label-cols-md="2">
          <b-form-input type="text" v-model="obj.endereco.bairro" autocomplete="off" :disabled="criterioBloqueioEdicao()"/>
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group :class="corTextoInput()" label="Número" label-align-md="right" label-size="" label-cols-md="4">
          <b-form-input type="text" v-model="obj.endereco.numero" autocomplete="off" :disabled="criterioBloqueioEdicao(config.isPrePostagem)"/>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group :class="corTextoInput()" label="Complemento" label-align-md="right" label-size="" label-cols-md="4">
          <b-form-input type="text" v-model="obj.endereco.complemento" autocomplete="off" :disabled="criterioBloqueioEdicao(config.isPrePostagem)"/>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group :class="corTextoInput()" label="Cidade" label-align-md="right" label-size="" label-cols-md="2">
          <b-form-input type="text" v-model="obj.endereco.cidade" autocomplete="off" :disabled="bloqueiaEndereco || criterioBloqueioEdicao()"/>
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group :class="corTextoInput()" label="UF" label-align-md="right" label-size="" label-cols-md="2" content-cols-md="4">
          <b-form-input type="text" maxlength="2" v-model="obj.endereco.uf" autocomplete="off" :disabled="bloqueiaEndereco || criterioBloqueioEdicao()"/>
        </b-form-group>
      </b-col>
			<!-- #endregion -->
    </b-form-row>

    <!-- #region cubagem -->
    <div v-if="exibeCubagem">
      <!-- #region nota fiscal e declarado -->
      <b-form-row>
        <b-col md="3">
          <b-form-group class="form-input-cinza" label="Chave NF" label-align-md="right" label-size="" label-cols-md="4">
            <b-form-input type="text" v-model="objVolume.chaveNotaFiscal" maxlength="44" @blur="defineNumeroNotaFiscal()" :disabled="config.atendimentoVencido"/>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group class="form-input-cinza" label="Nº NF" label-align-md="right" label-size="" label-cols-md="3">
            <b-form-input type="text" v-model="objVolume.numeroNotaFiscal" :disabled="config.atendimentoVencido"/>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group class="form-input-cinza" label="Valor NF" label-align-md="right" label-size="" label-cols-md="3">
            <b-form-input class="text-right" type="text" v-model="objVolume.valorNotaFiscal" @keyup="mascaraMoeda" :disabled="config.atendimentoVencido"/>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group class="form-input-cinza" label="Valor declarado" label-align-md="right" label-size="" label-cols-md="5">
            <b-form-input class="text-right" type="text" v-model="objVolume.valorDeclarado" @keyup="mascaraMoeda" :disabled="config.atendimentoVencido"/>
          </b-form-group>
        </b-col>
      </b-form-row>
      <!-- #endregion -->

      <!-- #region obs e adicionais -->
      <b-form-row>
          <b-col md="3">
              <b-form-group class="form-input-cinza" label="Adicionais" label-align-md="right" label-size="" label-cols-md="5">
                  <b-form-checkbox class="form-input-cinza" size="lg" v-model="objVolume.adicionais" value="AR" :disabled="config.atendimentoVencido" switch inline>
                      <span style="cursor: pointer; color: #808080; opacity: 0.7; font-size: 16px;">AR</span>
                  </b-form-checkbox>

                  <b-form-checkbox class="form-input-cinza" size="lg" v-model="objVolume.adicionais" value="AP" :disabled="config.atendimentoVencido" switch inline>
                      <span style="cursor: pointer; color: #808080; opacity: 0.7; font-size: 16px;">AP</span>
                  </b-form-checkbox>
              </b-form-group>
          </b-col>

          <b-col md="8">
              <b-form-group class="form-input-cinza" label="Observação" label-align-md="right" label-cols-md="2">
                  <b-form-input type="text" v-model="objVolume.observacoes[0]" :disabled="config.atendimentoVencido" />
              </b-form-group>
          </b-col>
      </b-form-row>
      <!-- #endregion -->

			<!-- #region tipo, dimensoes e peso -->
      <b-form-row class="mb-2 border border-dark pt-2 px-2" style="border-width:2px !important;">
        <b-col md="2">
          <b-form-group class="form-input-cinza" label="Tipo" label-align-md="right" label-cols-md="3">
            <b-form-select :options="$vsListaTiposObjetos()" v-model="objVolume.tipoObjeto" @change="liberaDimensoes()" :disabled="config.atendimentoVencido"/>
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group class="form-input-cinza" label="Altura (cm)" label-align-md="right" label-cols-md="6">
            <b-form-input ref="campoAltura" v-if="!config.atendimentoVencido && objVolume.dimensoesLiberadas" class="text-right" type="text" v-model="objVolume.altura" @keyup="mascaraInteiro" />
						<b-form-input v-else class="text-right" type="text" :value="objVolume.altura" disabled />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group class="form-input-cinza" label="Largura (cm)" label-align-md="right" label-cols-md="6">
            <b-form-input v-if="!config.atendimentoVencido && objVolume.dimensoesLiberadas" class="text-right" type="text" v-model="objVolume.largura" @keyup="mascaraInteiro" />
						<b-form-input v-else class="text-right" type="text" :value="objVolume.largura" disabled />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group class="form-input-cinza" label="Comprimento (cm)" label-align-md="right" label-cols-md="6">
            <b-form-input v-if="!config.atendimentoVencido && objVolume.dimensoesLiberadas" class="text-right" type="text" v-model="objVolume.comprimento" @keyup="mascaraInteiro" />
						<b-form-input v-else class="text-right" type="text" :value="objVolume.comprimento" disabled />
          </b-form-group>
        </b-col>

				<b-col md="3">
          <b-form-group class="form-input-cinza" label="Peso (gramas)" label-align-md="right" label-cols-md="6">
            <b-form-input ref="campoPeso" class="text-right" type="text" v-model="objVolume.peso" @keyup="mascaraInteiro" :disabled="config.atendimentoVencido" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <!-- #endregion -->

			<!-- #region opcoes -->
			<b-form-row v-if="!config.atendimentoVencido && objPagador.isAtivo">
				<b-col cols="6"/>
				<b-col md="2" v-if="config.isPrePostagem">
					<button type="button" class="btn-verde" @click="consultaTarifacao()">Listar</button>
        </b-col>
				<b-col md="2" v-if="config.isPrePostagem">
					<button 
						type="button" 
						class="btn-vermelho" 
						@click.once="defineAfericao(objVolumePrePostado)" 
						@keydown.enter.once="defineAfericao(objVolumePrePostado)" 
						@keypress.prevent>Postar</button>
        </b-col>
				<b-col cols="2" v-if="!config.isPrePostagem" />
				<b-col md="2" v-if="!config.isPrePostagem">
					<button type="button" class="btn-laranja" @click="consultaTarifacao()">Calcular frete</button>
				</b-col>
				<b-col cols="2"/>
			</b-form-row>
			<!-- #endregion -->

      <!-- #region tabela de frete -->
      <b-form-row>
        <b-col>
          <b-form-group class="form-input-cinza" label="Modalidade de envio" label-size="lg" label-cols-md="12" content-cols-md="10">
            <b-table class="text-nowrap h5" id="tabelaTarifacao" small responsive hover :items="config.tarifacaoServicos" :fields="tarifacaoCabecalho">

              <!--===================================================================================
              mapa de colunas (atua em campos sem tratamento) 
              se precisar debugar ou visualizar os dados para ajustes basta descomentar...

              obj.index = indice da linha
              obj.field = nome do campo/coluna/chave json
              obj.item  = objeto json bruto
              obj.value = o valor da chave json com tipo original mantido e sem tratamento, ex:
              ====================================================================================-->
              <!--<template #cell()="obj">
                {{ obj.field }}
                {{ obj.item }}
              </template>-->

              <!--===================================================================================
                celulas que precisam de tratamento, 
                sobrescrevem mapa de colunas pela identificacao da chave
                e nao precisam estar em ordem/sequencia
              ====================================================================================-->
              <template #cell(valorFrete)="obj">
                {{ $vsFormataMoeda(obj.value) }}
              </template>

              <template #cell(opcoes)="obj">
                <div v-if="!config.atendimentoVencido" class="pr-5">
                  <button 
										v-if="liberaFrete(obj.item)" 
										type="button" 
										class="btn-vermelho" 
										style="width: 120px; height: 35px;" 
										@click.once="defineServico(obj.item)" 
										@keydown.enter.once="defineServico(obj.item)" 
										@keypress.prevent>
										Selecionar
									</button>
                  <span v-else class="text-danger">Frete ultrapassa o limite do contrato escolhido</span>
                </div>
              </template>

            </b-table>
          </b-form-group>
        </b-col>
      </b-form-row>
      <!-- #endregion -->
    </div>
    <!-- #endregion -->
  
		<!-- #region modais -->
		<!-- #region clientes -->
		<b-modal v-if="buscarCliente" id="modalClientes" title="Escolha o pagador" size="lg">
      <div class="h5 modal-compactado">
        <div v-for="(cli, i) in lista" :key="cli.id" class="mb-4">
					<b-form-radio name="cliente" v-model="indiceLista" :value="i">
						<span style="cursor: pointer;">
							<div class="mb-2 font-weight-bold">{{ cli.nome }}</div>
							<div class="mb-2">CPF/CNPJ: {{ $vsFormataCpfCnpj(cli.cpfCnpj) }}</div>
							<div class="mb-2">
								<span v-if="cli.email && cli.email.length">Email: {{ cli.email }}</span>&nbsp;
								<span v-if="cli.telefone && cli.telefone.length">Telefone: {{ $vsFormataTelefone(cli.telefone) }}</span>
							</div>
							<div class="mb-2">
								{{ cli.endereco.logradouro }}, {{ cli.endereco.numero }}, {{ cli.endereco.bairro }}
								<span v-if="cli.endereco.complemento && cli.endereco.complemento.length">, {{ cli.endereco.complemento }}</span>
							</div>
							<div class="mb-2">
								CEP: {{ $vsFormataCep(cli.endereco.cep) }}, {{ cli.endereco.cidade }} / {{ cli.endereco.uf }}
							</div>
						</span>
					</b-form-radio>
				</div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <b-button type="button" class="float-right ml-2" variant="info" @click="confirmaCadastro()">Confirmar</b-button>
          <b-button type="button" class="float-right ml-2" variant="secondary" @click="hide()">Cancelar</b-button>
        </div>
      </template>
    </b-modal>
		<!-- #endregion -->

		<!-- #region remetente/destinatarios -->
		<b-modal v-if="buscarRemetente || buscarDestinatario" id="modalPessoas" :title="`Escolha o ${tipoPessoa}`" size="lg">
      <div class="h5 modal-compactado">
        <div v-for="(cli, i) in lista" :key="cli.id" class="mb-4">
					<b-form-radio name="pessoa" v-model="indiceLista" :value="i">
						<span style="cursor: pointer;">
							<div class="mb-2 font-weight-bold">{{ cli.nome }}</div>
							<div class="mb-2">CPF/CNPJ: {{ $vsFormataCpfCnpj(cli.cpfCnpj) }}</div>
							<div class="mb-2">
								<span v-if="cli.email && cli.email.length">Email: {{ cli.email }}</span>&nbsp;
								<span v-if="cli.telefone && cli.telefone.length">Telefone: {{ $vsFormataTelefone(cli.telefone) }}</span>
							</div>
							<div class="mb-2">
								{{ cli.endereco.logradouro }}, {{ cli.endereco.numero }}, {{ cli.endereco.bairro }}
								<span v-if="cli.endereco.complemento && cli.endereco.complemento.length">, {{ cli.endereco.complemento }}</span>
							</div>
							<div class="mb-2">
								CEP: {{ $vsFormataCep(cli.endereco.cep) }}, {{ cli.endereco.cidade }} / {{ cli.endereco.uf }}
							</div>
						</span>
					</b-form-radio>
				</div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <b-button type="button" class="float-right ml-2" variant="info" @click="confirmaCadastro()">Confirmar</b-button>
          <b-button type="button" class="float-right ml-2" variant="secondary" @click="hide()">Cancelar</b-button>
        </div>
      </template>
    </b-modal>
		<!-- #endregion -->
		<!-- #endregion -->
	</form>
</template>

<script>
export default {
  // #region configs
  name: "vs-form-atendimento-pessoa",
  props: {
    config: { type: Object, required: true },
    obj: { type: Object, required: true },
    objPostagem: { type: Object, default: null },
    objPagador: { type: Object, default: null },
    objRemetente: { type: Object, default: null },
    objVolume: { type: Object, default: null },
		objVolumePrePostado: { type: Object, default: null },
    buscarCliente: { type: Boolean, default: false },
    buscarRemetente: { type: Boolean, default: false },
    buscarDestinatario: { type: Boolean, default: false },
    exibeCubagem: { type: Boolean, default: false },
  },
  // #endregion

  // #region dados
  data() {
    return {
			tipoPessoa: "",
      lista: [],
			indiceLista: null,
      tarifacaoCabecalho: [
        //{ key: "transportadora.nome", sortable: true,  label: "Transportadora", },
        { key: "servicoDescricao", sortable: true, label: "Serviço", tdClass: "text-left", },
        { key: "prazoDias", sortable: true, label: "Prazo (dias)", tdClass: "text-center", },
        { key: "valorFrete", sortable: true, label: "Valor Frete", tdClass: "text-center", },
        { key: "observacaoFrete", sortable: false, label: "Observação", tdClass: "text-left", },
        { key: "opcoes", sortable: false, label: "Opções", tdClass: "text-center", },
      ],
			bloqueiaEndereco: true,
			cepInicial: "",
    }
  },
  // #endregion

  // #region watches
  watch: {
		"config.isPrePostagem"(ok) {
      if (ok && this.objVolume) {
				if (this.objVolume.tipoObjeto === this.VS_TIPO_VOLUME_ENVELOPE) {
					this.$refs.campoPeso.$el.focus()
				} else {
					this.$refs.campoAltura.$el.focus()
				}
      }
    }
  },
  // #endregion

  // #region triggers
  created() {
		this.bootstrap()
  },
  // #endregion

  // #region metodos
  methods: {
		bootstrap() {
			this.tipoPessoa = "Pagador"

			if (this.buscarRemetente) {
				this.tipoPessoa = "Remetente"
			}
			if (this.buscarDestinatario) {
				this.tipoPessoa = "Destinatário"
			}
		},
		limpaCadastro() {
			let obj = this.obj
			obj.id = null
      obj.email = ""
      obj.telefone = ""
      obj.contratos = []
      obj.vinculos = []
      obj.contratoSelecionado = null

			this.indiceLista = null
			this.lista = []
			this.limpaEndereco(true)
		},
		limpaEndereco(limpaCep = false) {
			let obj = this.obj

			obj.endereco.logradouro = ""
			obj.endereco.numero = ""
			obj.endereco.bairro = ""
			obj.endereco.cidade = ""
			obj.endereco.uf = ""
			obj.endereco.complemento = ""

			if (limpaCep) {
				obj.endereco.cep = ""
			}

			this.cepInicial = ""
			this.bloqueiaEndereco = false
		},
		async buscaClienteBlur() {
			let obj = this.obj
			let cpfCnpj = this.$vsLimpaFormatacao(obj.cpfCnpj)

			if ([11,14].includes(cpfCnpj.length)) {
				await this.buscaCliente()
			}
		},
    async buscaCliente(buscaNome=false) {
      let obj = this.obj
			let cpfCnpj = this.$vsLimpaFormatacao(obj.cpfCnpj)
			let nome = obj.nome.trim()
			let len = 2

			this.limpaCadastro()

			if (!buscaNome && cpfCnpj.length < len) {
				this.$vsNotificacao(`É necessário ao menos ${len} caracteres para realizar a busca`, "warning")
				return
			}
			if (buscaNome && nome.length < len) {
				this.$vsNotificacao(`É necessário ao menos ${len} caracteres para realizar a busca`, "warning")
				return
			}
			
			let criterio = "nome"
			let chave = criterio
			let valor = nome

			if (!buscaNome) {
				criterio = "CPF/CNPJ"
				chave = "cpfCnpj"
				valor = cpfCnpj
			}
			
			let sessao = this.$root.sessao
			let query = `idFilial=${sessao.filial.id}&idContratante=${sessao.contratante.id}&${chave}=${valor}`
      let resp = await this.$vsApiRest("GET", `/usuarios/clientes/?${query}`)

      if (resp.status === 200) {
				if (resp.data.length === 0) {
					if (!buscaNome) {
						valor = this.$vsFormataCpfCnpj(valor)
					}
					this.$vsNotificacao(`Nenhum cadastro encontrado com ${criterio}: ${valor}, continue com o cadastro`, "warning")
				} else if (resp.data.length > 1) {
					this.lista = resp.data
					this.$bvModal.show("modalClientes")
				} else if (resp.data.length === 1) {
					this.defineCliente(resp.data[0])
				}
      }
    },
		confirmaCadastro() {
			let i = this.indiceLista
			let msg = "Escolha o Pagador"
			let modal = "modalClientes"

			if (this.buscarRemetente) {
				msg = "Escolha o Remetente"
				modal = "modalPessoas"
			}
			if (this.buscarDestinatario) {
				msg = "Escolha o Destinatario"
				modal = "modalPessoas"
			}
			if (i === null) {
				this.$vsNotificacao(msg, "warning")
				return
			}
			if (this.buscarCliente) {
				this.defineCliente(this.lista[i])
			} else {
				this.definePessoa(this.lista[i])
			}
			this.$bvModal.hide(modal)
		},
    defineCliente(cliente) {
      this.$vsLoadingStart(true, 100) // simula carregamento

      // define pagador
      let obj = this.obj
      obj.id = cliente.id
      obj.cargo = cliente.cargo
      obj.isAtivo = cliente.isAtivo
      obj.isExcluido = cliente.isExcluido
      obj.nome = cliente.nome
      obj.cpfCnpj = this.$vsFormataCpfCnpj(cliente.cpfCnpj)
      obj.email = cliente.email
      obj.telefone = this.$vsFormataTelefone(cliente.telefone)
      obj.endereco = cliente.endereco
      obj.contratos = cliente.contratos
      obj.vinculos = cliente.vinculos
			obj.perfil = cliente.perfil
      obj.contratoSelecionado = null // default a vista
			obj.origemCadastro = cliente.origemCadastro
			obj.cadastroPivot = cliente.cadastroPivot
			obj.preCadastro = cliente.preCadastro

      if (obj.endereco) {
        obj.endereco.cep = this.$vsFormataCep(cliente.endereco.cep)

				this.cepInicial = cliente.endereco.cep
				this.bloqueiaEndereco = true
      }

      // remove contratos excluidos e reordena
      if (obj.contratos && obj.contratos.length > 0) {
        let tempContratos = []

        for (let i = 0; i < obj.contratos.length; i++) {
          if (!this.obj.contratos[i].isExcluido) {
            tempContratos.push(obj.contratos[i])
          }
        }

        obj.contratos = tempContratos
        this.$vsContratosOrdenacao(obj.contratos)

				let contratoPadrao = obj.contratos.find(el => el.isPadrao)

				if (contratoPadrao) {
					this.obj.contratoSelecionado = contratoPadrao
				}
      }

      this.config.isLiberada = obj.isAtivo  // libera ou nao a postagem
    },
    async buscaPessoa(buscaNome=false) {
      let obj = this.obj
			let cpfCnpj = this.$vsLimpaFormatacao(obj.cpfCnpj)
			let nome = obj.nome.trim()
			let len = 2

			this.limpaCadastro()

			if (!buscaNome && cpfCnpj.length < len) {
				this.$vsNotificacao(`É necessário ao menos ${len} caracteres para realizar a busca`, "warning")
				return
			}
			if (buscaNome && nome.length < len) {
				this.$vsNotificacao(`É necessário ao menos ${len} caracteres para realizar a busca`, "warning")
				return
			}

			let criterio = "nome"
			let chave = criterio
			let valor = nome

			if (!buscaNome) {
				criterio = "CPF/CNPJ"
				chave = "cpfCnpj"
				valor = cpfCnpj
			}

      let query = `idsAdmins=${this.$root.sessao.filial.id}&${chave}=${valor}`
      let resp = await this.$vsApiRest("GET", `/pessoas/?${query}`)
      
			if (resp.status === 200) {
        if (resp.data.length === 0) {
					if (!buscaNome) {
						valor = this.$vsFormataCpfCnpj(valor)
					}
					this.$vsNotificacao(`Nenhum cadastro encontrado com ${criterio}: ${valor}, continue com o cadastro`, "warning")
				} else if (resp.data.length > 1) {
					this.lista = resp.data
					this.$bvModal.show("modalPessoas")
				} else if (resp.data.length === 1) {
					this.definePessoa(resp.data[0])
				}
      }
    },
    definePessoa(pessoa) {
      this.$vsLoadingStart(true, 100) // simula carregamento

      let obj = this.obj
			obj.id = pessoa.id
      obj.nome = pessoa.nome
      obj.cpfCnpj = this.$vsFormataCpfCnpj(pessoa.cpfCnpj)
      obj.email = pessoa.email
      obj.telefone = this.$vsFormataTelefone(pessoa.telefone)
      obj.endereco = pessoa.endereco

      if (obj.endereco) {
        obj.endereco.cep = this.$vsFormataCep(pessoa.endereco.cep)

				this.cepInicial = pessoa.endereco.cep
				this.bloqueiaEndereco = true
      }
    },
    async buscaCep() {
      let obj = this.obj
      let cep = this.$vsLimpaFormatacao(obj.endereco.cep)

      if (this.cepInicial == cep || !cep.length || !this.validaCep(cep)) {
        return
      }

      let resp = await this.$vsApiRest("GET", `/tarifacao/?cep=${cep}`)

      obj.endereco.numero = ""
      obj.endereco.complemento = ""
			obj.endereco.logradouro = ""
      obj.endereco.bairro = ""
      obj.endereco.cidade = ""
      obj.endereco.uf = ""

      if (resp.status === 200) {
        let end = resp.data

				this.bloqueiaEndereco = !end.vazio
				obj.endereco.vazio = end.vazio

				if (end.vazio) {
					this.$vsNotificacao("Cep inválido", "warning")
					this.limpaEndereco()
					return
				}

        obj.endereco.logradouro = end.logradouro
        obj.endereco.bairro = end.bairro
        obj.endereco.cidade = end.cidade
        obj.endereco.uf = end.uf
				this.cepInicial = cep
      }
    },
		validaCep(cep) {
			let ok = true
			let len = this.$vsLimpaFormatacao(cep).length

      if (len && len < 8) {
        ok = false
        this.$vsNotificacao("Cep inválido", "warning")
      }
			return ok
		},
    mascaraCpfCnpj() {
      this.obj.cpfCnpj = this.$vsFormataCpfCnpj(this.obj.cpfCnpj)
    },
    mascaraTelefone() {
      this.obj.telefone = this.$vsFormataTelefone(this.obj.telefone)
    },
    mascaraCep(evt) {
			if (["backspace", "delete"].includes(evt.key.toLowerCase())) {
				this.limpaEndereco()
			}
      this.obj.endereco.cep = this.$vsFormataCep(this.obj.endereco.cep)
    },
    defineServico(obj) {
      let vol = this.objVolume

      vol.idServico = obj.idServico
      vol.idTransportadora = obj.idTransportadora

      vol.servicoCodigo = String(obj.servicoCodigo)
      vol.servicoDescricao = obj.servicoDescricao

      vol.valorFreteTransportadora = obj.valorFreteTransportadora
      vol.valorFreteContratanteBase = obj.valorFreteContratanteBase
      vol.valorFreteContratante = obj.valorFreteContratante
      vol.valorFreteTarifacao = obj.valorFreteTarifacao
      vol.valorFreteFilialBase = obj.valorFreteFilialBase
      vol.valorFreteFilial = obj.valorFreteFilial
      vol.valorFrete = obj.valorFrete

      vol.prazoDias = obj.prazoDias
      vol.observacaoFrete = obj.observacaoFrete

      this.config.definiuServico = true // notifica pagina pai (watch do vue)
    },
		defineAfericao(obj) {
			if (this.validaCadastros()) {
				this.defineServico(obj)
			}
		},
    async consultaTarifacao() {
	  if (!this.validaCadastros()) {
		return
	  }

      let vol = this.objVolume
      let cfg = this.config
      let valorNFe = this.$vsConverteMoeda(vol.valorNotaFiscal)
      let valorDecl = this.$vsConverteMoeda(vol.valorDeclarado)
      let sessao = this.$root.sessao

	  if (vol.tipoObjeto !== this.VS_TIPO_VOLUME_ENVELOPE) {
	    if (!vol.altura || vol.altura < 1) {
		  vol.altura = this.VS_DIMENSAO_MINIMA_ALTURA
		}
		if (!vol.largura || vol.largura < 1) {
		  vol.largura = this.VS_DIMENSAO_MINIMA_LARGURA
		}
		if (!vol.comprimento || vol.comprimento < 1) {
		  vol.comprimento = this.VS_DIMENSAO_MINIMA_COMPRIMENTO
		}
	  }

      let req = {
        idContratante: sessao.contratante.id,
        idFilial: sessao.filial.id,
        idTransportadora: cfg.idTransportadoraTravada,
        idServico: cfg.idServicoTravado,
        cepOrigem: this.$vsLimpaFormatacao(sessao.filial.endereco.cep),
        cepDestino: this.$vsLimpaFormatacao(this.obj.endereco.cep),
        peso: String(vol.peso),
        altura: String(vol.altura),
        largura: String(vol.largura),
        comprimento: String(vol.comprimento),
        valorDeclarado: (valorNFe > valorDecl) ? valorNFe.toFixed(2) : valorDecl.toFixed(2),
        adicionais: vol.adicionais,
				tipoObjeto: vol.tipoObjeto,
      }
      cfg.tarifacaoServicos = []

      let resp = await this.$vsApiRest("POST", "/tarifacao", req)

      if (resp.status === 200) {
        for (let i = 0; i < resp.data.length; i++) {
          let srv = resp.data[i]
          let valor = this.valorTotal(srv.valorFrete)
          srv.observacaoFrete = ""
          srv.opcoes = true

          if (valor < 0) {
            valor = 0
            srv.observacaoFrete = "Desconto maior do que o valor do frete!"
          }
          srv.valorFrete = valor
        }
        cfg.tarifacaoServicos = resp.data
      }
    },
    defineNumeroNotaFiscal() {
      let chave = String(this.objVolume.chaveNotaFiscal)
      this.objVolume.numeroNotaFiscal = (chave.length === 44) ? chave.substring(25, 34) : ""
    },
    valorTotal(valorFrete) {
      let contrato = this.objPagador.contratoSelecionado
      valorFrete = parseFloat(valorFrete)

      if (contrato && contrato.taxas[0]) {
        let taxa = contrato.taxas[0]
        let totalAcrescimo = (valorFrete * (Math.abs(taxa.percentualAcrescimo) / 100)) + Math.abs(taxa.valorAcrescimo)
        let totalDesconto = (valorFrete * (Math.abs(taxa.percentualDesconto) / 100)) + Math.abs(taxa.valorDesconto)

        valorFrete = parseFloat(parseFloat(valorFrete + totalAcrescimo - totalDesconto).toFixed(2))
      }
      return valorFrete
    },
    mascaraMoeda(evt) {
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
    mascaraInteiro(evt) {
      evt.target.value = this.$vsLimpaFormatacao(evt.target.value)
    },
    liberaFrete(vol) {
      let cont = this.objPagador.contratoSelecionado
      let temContrato = (cont !== null)
      let temContratoPosPago = (temContrato && cont.tipo === this.VS_CONTRATO_TIPO_POSPAGO)

      if (temContratoPosPago && vol.valorFrete > cont.saldoLimite) {
        return false
      }
      return true
    },
		liberaDimensoes() {
			let vol = this.objVolume
			let envolpe = (vol.tipoObjeto === this.VS_TIPO_VOLUME_ENVELOPE)

			if (envolpe) {
				vol.altura = "0"
				vol.largura = "0"
				vol.comprimento = "0"
			}

			vol.dimensoesLiberadas = !envolpe
		},
		validaCadastros() {
			let pai = this.$parent.$options.parent
			let pagOK = pai.validaCadastro(this.objRemetente)
			let remOK = pai.validaCadastro(this.objPagador)
			let desOK = pai.validaCadastro(this.obj, false, true)
      return (pagOK && remOK && desOK)
    },
		criterioBloqueioEdicao(liberadoPrePostagem = false) {
			let tipo = this.obj.tipoForm.toLowerCase().trim()
			let post = this.objPostagem
			let oprs = post.operacoes.filter(el => !el.isCancelado)
			let bloqueado = false

			switch (tipo) {
				case "pagador":
					let obj = this.obj
					let prods = post.produtosServicos
					let qtdVolsTotal = oprs.filter(el => el.volumes.length).map(el => el.volumes).filter(el => !el.isCancelado).length
					bloqueado = (obj.cadastroPivot || prods.length > 0 || qtdVolsTotal > 0)
					break

				case "remetente":
				case "destinatário":
					if (!liberadoPrePostagem) {
						let qtdVolsOpr = (oprs.length > 0) ? oprs[oprs.length - 1].volumes.filter(el => !el.isCancelado).length : 0
						bloqueado = (qtdVolsOpr > 0)
					}
					break
			}
			if (this.config.atendimentoVencido) {
				bloqueado = true
			}
			return bloqueado
		},
		corTextoInput() {
			let obj = this.obj
			let tipo = obj.tipoForm.toLowerCase().trim()
			return (tipo == "pagador" && obj.cadastroPivot) ? "form-input-branco" : "form-input-cinza"
		},
		corBackground() {
			let obj = this.obj
			let tipo = obj.tipoForm.toLowerCase().trim()
			return (tipo == "pagador" && obj.cadastroPivot) ? "background-color: #007F7F; padding: 10px;" : ""
		},
  },
  // #endregion
}
</script>
